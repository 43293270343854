<template>
  <div class="downAppPage">
    <div class="basepage">
      <div class="box">
        <div class="top">{{ $t('getapp.DownloadClient') }}</div>
        <div class="top1">{{ $t('getapp.Experiencemore') }}</div>
        <div class="boxApp">
          <div class="item" v-for="item in appList" :key="item.name">
            <div class="box1">
              <img class="icon" :src="item.icon" alt="" />
              <div class="name">{{ $t(item.name) }}</div>
              <div class="btn" @click="onClike(item)">{{ $t(item.btn_name) }}</div>
            </div>
            <div class="box2">
              <div class="imgBox">
                <img class="ewm" :src="$utils.getPng('downapp')" />
              </div>
              <div class="tit1">{{ $t('getapp.ScanQRcodedownload') }}</div>
            </div>
            <img v-if="item.name == 'Android客户端' || item.name == 'iOS客户端'" class="icon_right"
              :src="$utils.getPng('icon_men')" />
          </div>
        </div>
      </div>
      <!-- <div class="version">
        <a class="pointer" target="_blank" :href="`${$glb.fmUrl}/askdetail/86`">{{ $t('agreement.privacyPolicy') }}</a>
        <span class="line">|</span>
        <a class="pointer" target="_blank" :href="`${$glb.fmUrl}/askdetail/122`">{{ $t('agreement.userServiceAgreement') }}</a>
      </div> -->
    </div>
  </div>
</template>

<script>
import { clientDownUrlAPI } from "@/utils/apiList/home";
export default {
  data() {
    return {
      appList: [
        {
          name: "getapp.AndroidClient",
          id: 0,
          icon: this.$utils.getPng("icon_and"),
          btn_name: "getapp.DownloadNow",
        },
        {
          name: "getapp.iOSClient",
          id: 1,
          icon: this.$utils.getPng("icon_phone"),
          btn_name: "getapp.DownloadNow",
        },
        {
          name: "getapp.WindowsDesktop",
          id: 2,
          icon: this.$utils.getPng("icon_win"),
          btn_name: "getapp.DownloadNow",
        },
        {
          name: "getapp.MacOSDesktop",
          id: 3,
          icon: this.$utils.getPng("icon_mac"),
          btn_name: "getapp.DownloadNow",
        },
      ],
      exe: {},
      languageFlag: true
    };
  },
  mounted() {
    this.Dow();

  },
  methods: {
    onClike(item) {
      if (item.id == 2) {
        // this.$utils.newReported('C/1-5-10-019');
        // 创建链接元素
        var link = document.createElement("a");
        link.href = this.exe.win_url;
        link.download = "";
        // 模拟点击链接以下载文件
        link.click();
      } else if (item.id == 3) {
        // this.$utils.newReported('C/1-5-10-019');
        // 创建链接元素
        var link = document.createElement("a");
        link.href = this.exe.mac_url;
        link.download = "";
        // 模拟点击链接以下载文件
        link.click();
      }
    },
    Dow() {
      try {
        clientDownUrlAPI().then((res) => {
          this.exe = res?.data;
        });
      } catch (error) {
        // 处理异常
        console.error(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
